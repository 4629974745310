@font-face {
  font-family:'Sukhumvit';
  src: url('../fonts/SukhumvitSet-Text.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'Sukhumvit';
  src: url('../fonts/SukhumvitSet-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family:'Sukhumvit';
  src: url('../fonts/SukhumvitSet-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}