.select2-lg{
	padding-left: 0px;
}
.select2-lg .ui-select-match > span {
    height: 49px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 27px;
}
.select2-lg > input {
	height: 49px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 27px;
}
.select2-lg.ui-select-bootstrap > .ui-select-choices{
    font-size: 18px;
}
.ui-select-bootstrap > .ui-select-choices{
	width: 100%;
}
.ui-select-bootstrap .ui-select-choices-row>a>.fa {
    color: #4E85DB;
}
.ui-select-bootstrap .ui-select-choices-row.active>a>.fa {
    color: #fff !important;
}
.ui-select-match-text .fa{
    color: #4E85DB;
}