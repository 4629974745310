/* Include this file in your html if you are using the CSP mode. */

@charset "UTF-8";

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak],
.ng-cloak, .x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}

ng\:form {
  display: block;
}
